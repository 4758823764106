/** Статусы бронирования Back */
export const BookingStatuses = {
    Undefined: 'Undefined',                               // Неизвестный статус
    SelectPurchaseConditions: 'SelectPurchaseConditions', // Выбор способа покупки
    CheckPersonalData: 'CheckPersonalData',               // Проверка персональных данных
    Offer: 'Offer',                                       // Проверка договора оферты
    Payment: 'Payment',                                   // Требуется оплата
    Expired: 'Expired',                                   // Истекшее / Не завершено
    Wait: 'Wait',                                         // Требуется подтверждение менеджера
    Approved: 'Approved',                                 // Одобрено / Успешно забронировано
    Refusal: 'Refusal',                                   // Не одобрено / Результат не успешный
    Deal: 'Deal',                                         // Договор
    Cancelled: 'Cancelled',                               // Отменено
} as const;

/** Cтатусы бронирования UI */
export const BookingUIStatuses = {
    Undefined: 'Undefined',                               // Неизвестный статус
    InProgress: 'InProgress',                             // В процессе
    Expired: 'Expired',                                   // Истекшее / Не завершено
    Wait: 'Wait',                                         // Требуется подтверждение менеджера
    Approved: 'Approved',                                 // Одобрено / Успешно забронировано
    Refusal: 'Refusal',                                   // Не одобрено / Результат не успешный
    Deal: 'Deal',                                         // Договор
    Cancelled: 'Cancelled',                               // Отменено
} as const;

/** Cтатусы оплаты бронирования */
export const PaymentStatuses = {
    Undefined: 'Undefined',     // Неизвестный статус
    Created: 'Created',         // Создан.
    Approved: 'Approved',       // Удержание (холдирование).
    Deposited: 'Deposited',     // Завершено.
    Reversed: 'Reversed',       // Отменён.
    Refunded: 'Refunded',       // Возврат.
    Declined: 'Declined',       // Отклонен.
} as const;